
import { defineComponent, onUnmounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { hideModal } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { CommonHtmlType } from "@/core/directive/type/common";
import { Upload } from "@element-plus/icons-vue";
import { ElUpload } from "element-plus";
import JwtService from "@/core/services/JwtService";
import { bytesToSize } from "@/core/directive/function/common";

export default defineComponent({
  name: "import-files-modal",
  props: {
    fileList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    name: {
      type: String,
      default: "file",
    },
    fileId: {
      type: String,
      default: "",
    },
    uploadUrl: {
      type: String,
      default: "",
    },
    limit: {
      type: Number,
      default: 1,
    },
    accept: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    autoUpload: {
      type: Boolean,
      default: true,
    },
    clickToUpload: {
      type: String,
      default: "",
    },
    uploadAllText: {
      type: String,
      default: "",
    },
    removeAllText: {
      type: String,
      default: "",
    },
    uploadTip: {
      type: String,
      default: "",
    },
    uploadSuccess: {
      type: String,
      default: "",
    },
    listType: {
      type: String,
      default: "text",
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    clickClass: {
      type: String,
      default: "btn-primary me-2",
    },
    clickIcon: {
      type: String,
      default: "",
    },
    isShowRemoveAll: {
      type: Boolean,
      default: true,
    },
    isShowTip: {
      type: Boolean,
      default: false,
    },
    isShowList: {
      type: Boolean,
      default: true,
    },
    disabledClick: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "update-list",
    "change-file",
    "remove-file",
    "remove-files",
    "upload-status-change",
    "file-list-change",
    "multiple-upload-files",
  ],
  setup(props, { emit }) {
    const { t } = useI18n();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const elUploadListRef = ref<CommonHtmlType>(null);
    const uploadRef = ref<InstanceType<typeof ElUpload>>();
    const timer = ref();

    const uploadHeaders = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
      "X-LANG": JwtService.getLang(),
    });

    const { showServerErrorMsg } = mixin();

    const formData = ref({
      fileList: [] as any[],
      uploadList: [] as any[],
      // No files were selected
    });

    watch(
      () => props.fileList,
      (newValue) => {
        if (newValue) {
          formData.value.fileList = newValue;
        }
      }
    );

    const removeAll = () => {
      Swal.fire({
        text: t("common.removeFiles"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yesRemoveIt"),
        cancelButtonText: t("common.noReturn"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-light",
        },
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          uploadRef.value?.clearFiles();
          formData.value.fileList = [];
          emit("remove-files");
        }
      });
    };

    const removeItem = (item, index) => {
      Swal.fire({
        text: t("common.removeFiles"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yesRemoveIt"),
        cancelButtonText: t("common.noReturn"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-light",
        },
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          if (item.id) {
            formData.value.fileList.splice(index, 1);
          } else {
            uploadRef.value?.handleRemove(item, item.raw);
            formData.value.fileList.splice(index, 1);
          }
          emit("remove-file", item);
        }
      });
    };

    const setErrorUploadFile = () => {
      // uploadRef.value?.clearFiles();
    };

    const handleUploadExceed = (files, uploadFiles) => {
      // console.log(files, uploadFiles);
      if (!props.multiple) {
        uploadRef.value?.clearFiles();
        uploadRef.value?.handleStart(files[0]);
        uploadRef.value?.submit();
      }
    };

    const handleUploadError = () => {
      emit("upload-status-change", false);
      loading.value = false;
      setErrorUploadFile();
    };

    const handleUploading = (event, file, files) => {
      // console.log(event, file, files);
      loading.value = true;
      emit("upload-status-change", true);
    };

    const handleUploadChange = (file, files) => {
      formData.value.fileList = files;
      emit("file-list-change", formData.value.fileList);
      if (props.multiple) {
        if (file.status === "ready") {
          formData.value.uploadList.push(file);
        }
        multipleUploadFiles();
      }
    };

    const multipleUploadFiles = () => {
      if (!timer.value) {
        timer.value = setTimeout(() => {
          multipleUploadRequest();
        }, 30);
      } else {
        clearTimeout(timer.value);
        timer.value = setTimeout(() => {
          multipleUploadRequest();
        }, 30);
      }
    };

    const multipleUploadRequest = () => {
      const files = formData.value.uploadList;
      emit("multiple-upload-files", files);
      formData.value.uploadList = [];
      // setTimeout(() => {
      //   uploadRef.value?.submit();
      // }, 0);
    };

    const handleUploadSuccess = (res, file) => {
      loading.value = false;
      if (res.code == 0) {
        // Swal.fire({
        //   text: props.uploadSuccess || t("common.uploadSuccess"),
        //   icon: "success",
        //   buttonsStyling: false,
        //   confirmButtonText: t("common.okGotIt"),
        //   customClass: {
        //     confirmButton: "btn btn-primary",
        //   },
        // }).then(() => {
        //   emit("change-file", res);
        // });
        if (props.multiple) {
          // console.log(res, file);
          // file.forEach((item) => {
          //   item.id = res.data.data[props.fileId];
          // });
        } else {
          file.id = res.data[props.fileId];
        }
        emit("change-file", res);
      } else {
        showServerErrorMsg(res);
      }
      // formData.value.uploadList = [];
      emit("upload-status-change", false);
    };

    const handleDiscard = () => {
      hideModal(elUploadListRef.value);
      resetForm();
    };

    const resetForm = () => {
      uploadRef.value?.clearFiles();
    };

    onUnmounted(() => {
      if (timer.value) {
        clearTimeout(timer.value);
      }
    });

    return {
      t,
      bytesToSize,
      props,
      loading,
      formData,
      uploadRef,
      handleUploadExceed,
      handleUploadError,
      handleUploading,
      handleUploadChange,
      handleUploadSuccess,
      uploadHeaders,
      Upload,
      removeAll,
      removeItem,
    };
  },
});
