
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  ref,
} from "vue";
import { useRoute } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { useI18n } from "vue-i18n";
import mixin from "@/mixins";
import { ApiInbound, ApiInspection } from "@/core/api";
import {
  bytesToSize,
  CommonArrayToString,
  formatDate,
  formatDateTime,
} from "@/core/directive/function/common";
import ElUploadList from "@/components/upload/ElUploadList.vue";
import { inspectionDetail } from "@/core/directive/interface/inspection";
import { getInspectionStatusMap } from "@/core/directive/function/inspection";
import { CommonDateType } from "@/core/directive/type/common";
import { InspectionStatus } from "@/core/directive/type/inspection";
import { getUserWarhouseName } from "@/core/directive/function/user";
import Swal from "sweetalert2/dist/sweetalert2.js";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";
import { printClose, printConnect } from "@/core/directive/function/print";

export default defineComponent({
  name: "transport-management-shipment-detail",
  components: { ElUploadList, PermissionCommon },
  setup() {
    const { t } = useI18n();
    const currentInstance: any = getCurrentInstance();

    const { showServerErrorMsg } = mixin();

    const route = useRoute();

    const loading = ref(false);
    const uploadUrl = ApiInbound.uploadInboundItemImages();

    const formData = ref(Object.assign({}, inspectionDetail));

    const options = ref({
      fileList: [] as any[],
      uploadFiles: [] as any[],
      fulfillment_stage: new Map([]),
      inspectionDetail: false,
      images: false,
      uploading: false,
      maxFiles: 200,
    });

    const getImages = computed(() => {
      let arr: string[] = [];
      options.value.fileList.map((item) => {
        arr.push(item.url);
      });
      return arr;
    });

    const previewImages = (index: number) => {
      currentInstance.proxy.emitter.emit("preview-images", {
        sources: getImages.value,
        sourcesIndex: index,
        sourcesSlide: index + 1,
      });
    };

    const removeItem = async (item, index) => {
      const { data } = await ApiInbound.deleteInboundItemImage({
        id: route.params.id,
        file_upload_record_id: item.id,
      });
      loading.value = false;
      if (data.code == 0) {
        options.value.fileList.splice(index, 1);
      } else {
        showServerErrorMsg(data);
      }
    };

    const multipleUpload = async (files) => {
      if (
        options.value.fileList.length + files.length >
        options.value.maxFiles
      ) {
        Swal.fire({
          title: "",
          text: t("common.uploadFiles", [options.value.maxFiles]),
          icon: "error",
        });
        return;
      }
      options.value.uploadFiles = files;
      const params = new FormData();
      params.append("id", route.params.id as string);
      files.map((item) => {
        params.append("file[]", item.raw, item.raw.name);
      });
      options.value.uploading = true;
      const { data } = await ApiInbound.uploadInboundItemImagesRequest(params);
      options.value.uploading = false;
      if (data.code === 0) {
        let arr: any[] = [];
        data.data.data.map((item) => {
          arr.push({
            id: item.file_upload_record_id,
            name: item.name,
            size: item.size,
            url: item.url,
          });
        });
        options.value.fileList.push(...arr);
      } else {
        showServerErrorMsg(data);
      }
    };

    const getShowInfo = async () => {
      options.value.inspectionDetail = true;
      const { data } = await ApiInspection.getInspectionHeaderInfo({
        id: route.params.id,
      });
      options.value.inspectionDetail = false;
      if (data.code == 0) {
        formData.value = data.data;
      } else {
        showServerErrorMsg(data);
      }
    };

    const getInboundInfo = async () => {
      options.value.images = true;
      const { data } = await ApiInbound.getInboundInfo({
        id: route.params.id,
      });
      options.value.images = false;
      if (data.code == 0) {
        let mockList: any[] = [];
        data.data.upload_images.map((item) => {
          mockList.push({
            id: item.id,
            name: item.name,
            size: item.size,
            url: item.url,
          });
        });
        options.value.fileList = mockList;
      } else {
        showServerErrorMsg(data);
      }
    };

    const getFromInfo = async () => {
      loading.value = true;
      Promise.all([getShowInfo(), getInboundInfo()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const customFormatDateTime = (
      date: CommonDateType,
      format = "YYYY-MM-DD HH:mm"
    ) => {
      return formatDateTime(date, format);
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      getFromInfo();
      printConnect();
    });

    onUnmounted(() => {
      printClose();
    });

    return {
      t,
      formatDate,
      formatDateTime,
      bytesToSize,
      CommonArrayToString,
      InspectionStatus,
      getInspectionStatusMap,
      customFormatDateTime,
      getUserWarhouseName,
      loading,
      uploadUrl,
      formData,
      options,
      previewImages,
      removeItem,
      multipleUpload,
      getFromInfo,
    };
  },
});
